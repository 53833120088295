
:root {
  --background: #ffffff;
  --foreground:  #272731;
}
/*
@media (prefers-color-scheme: dark) {
  :root {
    --background: #272731;
    --foreground: #ededed;
  }
}
*/

body {
  color: var(--foreground);
  background: var(--background);
  font-family: 'Comfortaa', sans-serif;
}

img {
  pointer-events: none;
  user-select: none;
}

.material-symbols-outlined {
  font-variation-settings:
          'FILL' 0,
          'wght' 400,
          'GRAD' 0,
          'opsz' 24
}